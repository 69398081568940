import React, { useEffect, useState } from "react";
import { Button, CardTitle } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { formatDate, getCookie } from "../../utils/Utils";
import { getUpcomingEventsList } from "../../http/getApi";
import { useQuery } from "react-query";
import { ReactDataTable } from "../../components/Component";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import client from "../../client.json";
import { ShimmerTable } from "react-shimmer-effects";

// let token = process.env.REACT_APP_AUTH_TOKEN;

const Overview = () => {
  const navigate = useNavigate();
  const activeVariable = getActiveEnvironmentVariable();
  const [recordLimit, setRecordLimit] = useState(5);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);

  const {
    data: upcomingEventsList,
    isLoading: upcomingEventsLoading,
    isError: upcomingEventsListError,
  } = useQuery({
    queryKey: ["get-upcoming-events-list", recordLimit, pageNo, search],
    queryFn: () =>
      getUpcomingEventsList({
        id: "",
        pageNo,
        recordLimit,
        search: search,
      }),
    onSuccess: () => setLoading(false),
  });

  useEffect(() => {
    if (upcomingEventsList?.totalRecords) {
      setTotalRecords(upcomingEventsList.totalRecords);
    }
  }, [upcomingEventsList, setTotalRecords]);

  const OpenEventDetails = (id) => {
    navigate(`/my-events/event-details/${id}`);
  };
  // const DataTableData = [
  //   {
  //     id: 0,
  //     name: "Francine Kirby",
  //     age: 24,
  //     gender: "female",
  //     company: "BUZZWORKS",
  //     startDate: "2017-02-17",
  //     salary: "$2,570.39",
  //   },
  //   {
  //     id: 1,
  //     name: "Reva Best",
  //     age: 40,
  //     gender: "female",
  //     company: "MARQET",
  //     startDate: "2021-10-14",
  //     salary: "$1,488.76",
  //   },
  //   {
  //     id: 2,
  //     name: "Alexis Flores",
  //     age: 21,
  //     gender: "female",
  //     company: "OBONES",
  //     startDate: "2020-04-28",
  //     salary: "$1,336.93",
  //   },
  //   {
  //     id: 3,
  //     name: "Nixon Sullivan",
  //     age: 30,
  //     gender: "male",
  //     company: "SLUMBERIA",
  //     startDate: "2016-10-08",
  //     salary: "$2,156.70",
  //   },
  //   {
  //     id: 4,
  //     name: "Foreman Wooten",
  //     age: 20,
  //     gender: "male",
  //     company: "ESCENTA",
  //     startDate: "2018-07-12",
  //     salary: "$3,057.42",
  //   },
  //   {
  //     id: 5,
  //     name: "Franco Davis",
  //     age: 28,
  //     gender: "male",
  //     company: "ZILLACON",
  //     startDate: "2015-10-08",
  //     salary: "$2,730.88",
  //   },
  // ];

  const dataTableColumns = [
    {
      name: "Sr. No.",
      selector: (row, index) => pageNo * recordLimit + index + 1,
    },
    {
      name: `${client?.[activeVariable]?.sectin1
        ?.map((item) => item?.title3)
        .join(", ")} Name`,

      selector: (row) => row.event_name,
    },
    {
      name: `${client?.[activeVariable]?.sectin1
        ?.map((item) => item?.title3)
        .join(", ")} Type`,
      selector: (row) => row.event_type_name,
    },
    {
      name: `${client?.[activeVariable]?.sectin1
        ?.map((item) => item?.title3)
        .join(", ")} Date`,
      selector: (row) => row.start_date_time,

      cell: (row) => <span>{formatDate(row.start_date_time)}</span>,
    },
    {
      name: "Venue Name",
      selector: (row) =>
        row.venue_details[0]?.address
          ? row.venue_details[0]?.address
          : "-------",
    },
    {
      name: "Event Status",
      selector: (row) => row.booking_request_status,

      cell: (row) =>
        row.booking_request_status === "0" ? (
          <span className="text-warning">Pending</span>
        ) : row.booking_request_status === "1" ? (
          <span className="text-primary">Accept</span>
        ) : (
          <span className="text-danger">Cancel</span>
        ),
    },
    {
      name: "Details",
      selector: (row) => row.id,
      cell: (row) => (
        <Button
          className="text-white px-1 py-1 btn-primary"
          onClick={() => OpenEventDetails(row?.id)}
        >
          <span className="sub-text text-white">
            {client?.[activeVariable]?.sectin1?.map((item) => item?.title3)}{" "}
            Details
          </span>
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="border rounded">
        <div className="card-inner">
          <div className="card-title-group">
            <CardTitle>
              <h6 className="title">
                <h4 className="me-2">
                  All{" "}
                  {client?.[activeVariable]?.sectin1?.map(
                    (item) => item?.title1
                  )}
                </h4>
                {""}
                {/* <Link
                to={`${process.env.PUBLIC_URL}event-list`}
                className="link d-block d-md-inline "
              >
                History
              </Link> */}
              </h6>
            </CardTitle>
          </div>
        </div>

        <div>
          <label>
            <input
              type="search"
              className="ms-2 mb-1 form-control form-control-sm w-250px border-2"
              placeholder="Search..."
              onChange={(ev) => {
                setSearch(ev.target.value);
              }}
            />
          </label>
        </div>
        {upcomingEventsLoading ? (
          <div>
            <ShimmerTable row={5} />
          </div>
        ) : (
          <div className="p-2">
            <ReactDataTable
              data={upcomingEventsList.data || []}
              columns={dataTableColumns}
              searchBy={"event_name"}
              actions
              pageNo={setPageNo}
              totalRecords={totalRecords}
              activePage={pageNo || 0}
              expandableRows
              setSearch={setSearch}
              loading={loading}
              recordLimit={recordLimit}
              setRecordLimit={setRecordLimit}
              pagination
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default Overview;
