import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useNavigate, useParams } from "react-router";
import classNames from "classnames";
import PaymentDetails from "./PaymentDetails";
import { Icon } from "../../../../components/Component";
import { IoIosStar } from "react-icons/io";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { useQuery } from "react-query";
import {
  getPaymentDetails,
  getUpcomingEventsList,
} from "../../../../http/getApi";
import { getRequestedSong } from "../../../../http/getApi";
import Agreement from "./Agreement";
import {
  formatDate,
  formatTime,
  convertToUserTimezone,
  getActiveEnvironmentVariable,
} from "../../../../utils/Utils";
import Information from "./Information";
import TransactionDetails from "./TxnDetails";
import Review from "./Review";
import client from "../../../../client.json";
import InvoiceDetails from "./Invoice/Invoice";
import Invoice from "./Invoice/Invoice";
import RequestedSong from "./RequestedSong";
const EventDetails = () => {
  const activeVariable = getActiveEnvironmentVariable();
  const [isTotalInvoice, setIsTotalInvoice] = useState(true);
  const [isShowSingleInvoice, setIsShowSingleInvoice] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const [activeTab, setActivetab] = useState("1");
  const toggleIconTab = (tab) => {
    if (activeTab !== tab) {
      setActivetab(tab);
      setIsTotalInvoice(true);
    }
  };

  //fetching event data
  const {
    data: upcomingEvent,
    isLoading: upcomingEventLoading,
    isError: upcomingEventListError,
  } = useQuery({
    queryKey: ["get-upcoming-event-by-id", id],
    queryFn: () => getUpcomingEventsList({ id: id, page: "", limit: "" }),
  });

  //fetching event payment details
  const {
    data: paymentDetails,
    isLoading: paymentDetailsLoading,
    isError: paymentDetailsError,
  } = useQuery({
    queryKey: ["get-paymentDetails-by-clientId", id],
    queryFn: () =>
      getPaymentDetails({ id: id, page: "", limit: "", search: "" }),
  });

  //const [addonData, setAddonData] = useState([]);

  //Use the state updater function to update the addonData state

  // useEffect(() => {
  //   if (upcomingEvent?.data?.[0]?.addons) {
  //     setAddonData(upcomingEvent.data[0].addons);
  //   }
  // }, [upcomingEvent]);
  const addonAllData = upcomingEvent?.data[0]?.addons;
  const bookingStatus = upcomingEvent?.data[0]?.booking_request_status;

  const addonName = upcomingEvent?.data[0]?.addons?.[0]?.addon_name;
  const addonQuantity = upcomingEvent?.data[0]?.addons?.[0]?.quantity;
  const addonPrice = upcomingEvent?.data[0]?.addons?.[0]?.price;

  const clientName = upcomingEvent?.data[0]?.client_details?.[0]?.first_name;
  const clientLastName = upcomingEvent?.data[0]?.client_details?.[0]?.last_name;
  const username = upcomingEvent?.data[0]?.client_details?.[0]?.username;
  const email = upcomingEvent?.data[0]?.client_details?.[0]?.email;
  const companyUserName = upcomingEvent?.data[0]?.company_username;
  const Address1 = upcomingEvent?.data[0]?.client_details?.[0]?.address_1;
  const Address2 = upcomingEvent?.data[0]?.client_details?.[0]?.address_2;
  const clientstate = upcomingEvent?.data[0]?.client_details?.[0]?.state_name;
  const clientcountry =
    upcomingEvent?.data[0]?.client_details?.[0]?.country_name;
  const clientcity = upcomingEvent?.data[0]?.client_details?.[0]?.city_name;
  const clientzipcode = upcomingEvent?.data[0]?.client_details?.[0]?.zip_code;

  const organizationName =
    upcomingEvent?.data[0]?.client_details?.[0]?.organization_name;

  const eventName = upcomingEvent?.data[0]?.event_name;
  const packageName =
    upcomingEvent?.data[0]?.package_details?.[0]?.package_name;
  const eventType = upcomingEvent?.data[0]?.event_type_name;
  const address = upcomingEvent?.data[0]?.venue_details?.[0]?.address;
  const zipCode = upcomingEvent?.data[0]?.venue_details?.[0]?.zip_code;
  const city = upcomingEvent?.data[0]?.venue_details[0]?.city_name;
  const eventdate = formatDate(upcomingEvent?.data[0]?.start_date_time);
  const contactNumber =
    upcomingEvent?.data[0]?.client_details?.[0]?.work_phone_number;
  const packagePrice =
    upcomingEvent?.data?.[0]?.package_details?.[0]?.package_price;
  const packageDuration =
    upcomingEvent?.data?.[0]?.package_details?.[0]?.package_duration;
  const retainerPrice =upcomingEvent?.data?.[0]?.event_financials?.retainer_value;
  console.log("retainer value 463:",retainerPrice);
  const addOnTotal = upcomingEvent?.data?.[0]?.event_financials?.addon_total;
  const travelFee = upcomingEvent?.data[0]?.venue_details[0]?.travel_cost || 0;
  const eventcity = upcomingEvent?.data[0]?.venue_details[0]?.city_name || "";
  const eventstate = upcomingEvent?.data[0]?.venue_details[0]?.state_name || "";
  const eventcountry =
    upcomingEvent?.data[0]?.venue_details[0]?.country_name || "";
  const eventzipcode = upcomingEvent?.data[0]?.venue_details[0]?.zip_code || 0;
  const discount1 = upcomingEvent?.data[0]?.event_financials?.discount_1 || 0;
  const discount2 = upcomingEvent?.data[0]?.event_financials?.discount_2 || 0;
  const overtimeRateHourly =
    upcomingEvent?.data[0]?.event_financials?.overtime_rate_hourly || 0;

  const overtime_hours =
    upcomingEvent?.data[0]?.event_financials?.overtime_hours || 1;

  const Totalovertimehours = overtimeRateHourly * overtime_hours;

  const InvoiceTravelFee =
    upcomingEvent?.data[0]?.event_financials?.travel_fee || 0;
  const invoiceId = upcomingEvent?.data[0]?.event_financials?.invoiceId || 0;
  const companyid = upcomingEvent?.data[0]?.user_id;

  const balanceDueAfterRetainer =
    upcomingEvent?.data[0]?.event_financials?.balance_due_after_retainer || 0;

 

  const scheduledPayment2 =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_2;
  const scheduledPayment3 =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_3;
  const scheduledPayment2Date = formatDate(
    upcomingEvent?.data?.[0]?.scheduled_payment_2
  );

  const totalFee = retainerPrice + scheduledPayment2 + scheduledPayment3;

 
  const scheduledPayment3Date = formatDate(
    upcomingEvent?.data?.[0]?.scheduled_payment_3
  );
  const eventFinancials = upcomingEvent?.data?.[0]?.event_financials;

  const guestCount = upcomingEvent?.data[0]?.guest_count;

  const setUpTime = convertToUserTimezone(upcomingEvent?.data[0]?.setup_time);
  const startTime = convertToUserTimezone(upcomingEvent?.data[0]?.start_date_time);
  const endTime = convertToUserTimezone(upcomingEvent?.data[0]?.end_date_time);

  // console.log("start time:", new Date(upcomingEvent?.data[0]?.start_date_time).toISOString());
  

  const userId = upcomingEvent?.data?.[0]?.user_id;
  const retainerTxnId =
    upcomingEvent?.data?.[0]?.event_financials?.retainer_txn || "";
  const schedulePay2TxnId =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_2_txn || "";
  const schedulePay3TxnId =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_3_txn || "";
  const retainerPaidDate = formatDate(
    paymentDetails?.data?.done_payment?.retainer_payment?.created_at
  );

  const retainer_mode =
    upcomingEvent?.data?.[0]?.event_financials?.retainer_mode;

  const scheduled_payment_2_mode =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_2_mode;
  const scheduled_payment_3_mode =
    upcomingEvent?.data?.[0]?.event_financials?.scheduled_payment_3_mode;


  const package_amount= upcomingEvent?.data?.[0]?.event_financials?.package_amount;


  console.log("package_amount:",package_amount);
  const retainerPaymentStatus =
    upcomingEvent?.data?.[0]?.event_financials?.payment_status;

  const scheduledPay2Status =
    upcomingEvent?.data?.[0]?.event_financials?.payment_status;

  const scheduledPay3Status =
    upcomingEvent?.data?.[0]?.event_financials?.payment_status;

  const total_remaining_payment =
    upcomingEvent?.data?.[0]?.event_financials?.total_remaining_payment;

  const scheduled2PaidDate = formatDate(
    paymentDetails?.data?.done_payment?.scheduled_payment_2?.created_at
  );

  const scheduled3PaidDate = formatDate(
    paymentDetails?.data?.done_payment?.scheduled_payment_3?.created_at
  );

  const status = upcomingEvent?.data?.[0]?.event_financials?.status;

  return (
    <>
      <div className="mt-3 px-2">
        <h4>
          {client?.[activeVariable]?.sectin1?.map((item) => item?.title3)}{" "}
          Details
        </h4>
        <div class="card card-preview mt-3">
          <div className="card-inner pt-0">
            <Nav tabs>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "1" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <em class="icon ni ni-info-i"></em>
                  <span>Information </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classNames({ active: activeTab === "2" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("2");
                  }}
                >
                  <em class="icon ni ni-music"></em>
                  <span>Requested Song </span>
                </NavLink>
              </NavItem>
              {bookingStatus == "1" && (
                <>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classNames({ active: activeTab === "3" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("3");
                      }}
                    >
                      <em class="icon ni ni-calender-date-fill"></em>
                      <span className="px-1">Financials</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classNames({ active: activeTab === "4" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("4");
                      }}
                    >
                      <Icon name="sign-usd"></Icon>
                      <span>Transaction Details</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classNames({ active: activeTab === "5" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("5");
                      }}
                    >
                      <Icon name="file-plus"></Icon>
                      <span className="px-1">Agreement</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classNames({ active: activeTab === "6" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleIconTab("6");
                      }}
                    >
                      <LiaFileInvoiceDollarSolid className="fs-4" />
                      <span className="px-2">Invoice</span>
                    </NavLink>
                  </NavItem>
                  {retainerTxnId ? (
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classNames({ active: activeTab === "7" })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleIconTab("7");
                        }}
                      >
                        <IoIosStar />
                        <span className="px-2">Review</span>
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Information
                  upcomingEvent={upcomingEvent}
                  guestCount={guestCount}
                  eventName={eventName}
                  eventType={eventType}
                  eventdate={eventdate}
                  setUpTime={setUpTime}
                  startTime={startTime}
                  endTime={endTime}
                  packageName={packageName}
                  packagePrice={packagePrice}
                  address={address}
                  zipCode={zipCode}
                  city={city}
                />
              </TabPane>

              <TabPane tabId="2">
                <RequestedSong />
              </TabPane>

              <TabPane tabId="3">
                <PaymentDetails
                  upcomingEvent={upcomingEvent}
                  packagePrice={packagePrice}
                  package_amount={package_amount}
                  packageDuration={packageDuration}
                  retainerPrice={retainerPrice}
                  retainerTxnId={retainerTxnId}
                  schedulePay2TxnId={schedulePay2TxnId}
                  schedulePay3TxnId={schedulePay3TxnId}
                  scheduledPay2Status={scheduledPay2Status}
                  scheduledPay3Status={scheduledPay3Status}
                  retainerPaymentStatus={retainerPaymentStatus}
                  retainer_mode={retainer_mode}
                  scheduled_payment_2_mode={scheduled_payment_2_mode}
                  scheduled_payment_3_mode={scheduled_payment_3_mode}
                  addOnTotal={addOnTotal}
                  InvoiceTravelFee={InvoiceTravelFee}
                  overtimeRateHourly={Totalovertimehours}
                  discount1={discount1}
                  discount2={discount2}
                  balanceDueAfterRetainer={balanceDueAfterRetainer}
                  totalFee={totalFee}
                  startTime={startTime}
                  endTime={endTime}
                  scheduledPayment2={scheduledPayment2}
                  scheduledPayment3={scheduledPayment3}
                  scheduledPayment2Date={scheduledPayment2Date}
                  scheduledPayment3Date={scheduledPayment3Date}
                  eventFinancials={eventFinancials}
                  userId={userId}
                  paymentDetails={paymentDetails}
                  paymentDetailsLoading={paymentDetailsLoading}
                  clientName={clientName}
                  eventdate={eventdate}
                />
              </TabPane>

              <TabPane tabId="4">
                <TransactionDetails
                  username={username}
                  address={address}
                  contactNumber={contactNumber}
                  invoiceId={invoiceId}
                  packagePrice={packagePrice}
                  packageDuration={packageDuration}
                  startTime={startTime}
                  endTime={endTime}
                  addOnTotal={addOnTotal}
                  InvoiceTravelFee={InvoiceTravelFee}
                  retainer_mode={retainer_mode}
                  scheduled_payment_2_mode={scheduled_payment_2_mode}
                  scheduled_payment_3_mode={scheduled_payment_3_mode}
                  discount1={discount1}
                  discount2={discount2}
                  totalFee={totalFee}
                  packageName={packageName}
                  retainerPrice={retainerPrice}
                  balanceDueAfterRetainer={balanceDueAfterRetainer}
                  scheduledPayment2={scheduledPayment2}
                  scheduledPayment3={scheduledPayment3}
                  retainerTxnId={retainerTxnId}
                  schedulePay2TxnId={schedulePay2TxnId}
                  schedulePay3TxnId={schedulePay3TxnId}
                  paymentDetails={paymentDetails}
                  clientName={clientName}
                  eventdate={eventdate}
                  retainerPaidDate={retainerPaidDate}
                  scheduled2PaidDate={scheduled2PaidDate}
                  scheduled3PaidDate={scheduled3PaidDate}
                  scheduledPayment2Date={scheduledPayment2Date}
                  scheduledPayment3Date={scheduledPayment3Date}
                  setActivetab={setActivetab}
                  setIsTotalInvoice={setIsTotalInvoice}
                  isTotalInvoice={isTotalInvoice}
                  setIsShowSingleInvoice={setIsShowSingleInvoice}
                />
              </TabPane>
              <TabPane tabId="5">
                <Agreement
                  clientName={clientName}
                  clientLastName={clientLastName}
                  email={email}
                  organizationName={organizationName}
                  eventName={eventName}
                  eventdate={eventdate}
                  setUpTime={setUpTime}
                  startTime={startTime}
                  endTime={endTime}
                  packageName={packageName}
                  packagePrice={packagePrice}
                  packageDuration={packageDuration}
                  addOnTotal={addOnTotal}
                  overtimeRateHourly={Totalovertimehours}
                  discount1={discount1}
                  discount2={discount2}
                  totalFee={totalFee}
                  InvoiceTravelFee={InvoiceTravelFee}
                  retainerPrice={retainerPrice}
                  balanceDueAfterRetainer={balanceDueAfterRetainer}
                  Address1={Address1}
                  Address2={Address2}
                  address={address}
                  companyUserName={companyUserName}
                  addonAllData={addonAllData}
                  addonName={addonName}
                  addonQuantity={addonQuantity}
                  addonPrice={addonPrice}
                  clientcity={clientcity}
                  clientcountry={clientcountry}
                  clientstate={clientstate}
                  clientzipcode={clientzipcode}
                  eventType={eventType}
                  contactNumber={contactNumber}
                  eventcity={eventcity}
                  eventstate={eventstate}
                  eventcountry={eventcountry}
                  eventzipcode={eventzipcode}
                  scheduledPayment2={scheduledPayment2}
                  scheduledPayment3={scheduledPayment3}
                  scheduledPayment2Date={scheduledPayment2Date}
                  scheduledPayment3Date={scheduledPayment3Date}
                  schedulePay2TxnId={schedulePay2TxnId}
                  schedulePay3TxnId={schedulePay3TxnId}
                  retainerTxnId={retainerTxnId}
                  retainerPaidDate={retainerPaidDate}
                  scheduled2PaidDate={scheduled2PaidDate}
                  scheduled3PaidDate={scheduled3PaidDate}
                  status={status}
                />
              </TabPane>
              <TabPane tabId="6">
                <Invoice
                  isTotalInvoice={isTotalInvoice}
                  isShowSingleInvoice={isShowSingleInvoice}
                  clientName={clientName}
                  clientLastName={clientLastName}
                  username={username}
                  address={address}
                  contactNumber={contactNumber}
                  invoiceId={invoiceId}
                  packagePrice={packagePrice}
                  packageDuration={packageDuration}
                  startTime={startTime}
                  endTime={endTime}
                  addOnTotal={addOnTotal}
                  InvoiceTravelFee={InvoiceTravelFee}
                  overtimeRateHourly={Totalovertimehours}
                  discount1={discount1}
                  discount2={discount2}
                  totalFee={totalFee}
                  retainerTxnId={retainerTxnId}
                  schedulePay2TxnId={schedulePay2TxnId}
                  schedulePay3TxnId={schedulePay3TxnId}
                  retainerPrice={retainerPrice}
                  balanceDueAfterRetainer={balanceDueAfterRetainer}
                  scheduledPayment2={scheduledPayment2}
                  scheduledPayment3={scheduledPayment3}
                  retainerPaidDate={retainerPaidDate}
                  scheduled2PaidDate={scheduled2PaidDate}
                  scheduled3PaidDate={scheduled3PaidDate}
                />
              </TabPane>

              <TabPane tabId="7">
                <Review companyid={companyid} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};
export default EventDetails;
