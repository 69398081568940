import React, { useState, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import { Card } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../../../css/style.css";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../../components/Component";
import {
  formatDate,
  getActiveEnvironmentVariable,
} from "../../../../utils/Utils";
import { toast } from "react-toastify";
import client from "../../../../client.json";

const TransactionDetails = ({
  retainer_mode,
  scheduled_payment_2_mode,
  scheduled_payment_3_mode,
  retainerPrice,
  scheduledPayment2,
  scheduledPayment3,
  retainerTxnId,
  balanceDueAfterRetainer,
  schedulePay2TxnId,
  schedulePay3TxnId,
  paymentDetails,
  clientName,
  eventdate,
  retainerPaidDate,
  scheduled2PaidDate,
  scheduled3PaidDate,
  scheduledPayment2Date,
  scheduledPayment3Date,
  setActivetab,
  isTotalInvoice,
  setIsTotalInvoice,
  setIsShowSingleInvoice,
}) => {
  const { id } = useParams();
  console.log("Event id from Tinvoice:", id);
  const navigate = useNavigate();
  const [copiedTxnId, setCopiedTxnId] = useState(null);
  function copyTxnId(elementId) {
    const txnId = document.getElementById(elementId).textContent;
    const activeVariable = getActiveEnvironmentVariable();
    const tempInput = document.createElement("input");
    tempInput.value = txnId;

    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    toast.success("Transaction ID copied to clipboard: " + txnId);

    setCopiedTxnId(elementId);
    setTimeout(() => {
      setCopiedTxnId(null);
    }, 3000);
  }
  const activeVariable = getActiveEnvironmentVariable();
  return (
    <React.Fragment>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Transaction Details </BlockTitle>
              <BlockDes>
                <p className="fs-6">
                  "All the details about your transaction".
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none"></BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          {retainerTxnId ? (
            <div className="nk-data data-list">
              <div className="mb-2 table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th className="text-center" scope="col">
                        Payment Type
                      </th>
                      <th className="text-center" scope="col">
                        Amount
                      </th>
                      <th className="text-center" scope="col">
                        Payment Mode
                      </th>
                      <th className="text-center" scope="col">
                        Transaction Id
                      </th>
                      <th className="text-center" scope="col">
                        Date
                      </th>
                      <th className="text-center" scope="col">
                        Invoice
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {retainerTxnId && (
                      <tr className="fw-normal">
                        <td className="text-center">Retainer</td>
                        <td className="text-center">${retainerPrice}</td>
                        <td className="text-center">
                          {retainer_mode === "1"
                            ? "Stripe"
                            : retainer_mode === "3"
                            ? "Paypal"
                            : "Pending"}
                        </td>
                        <td className="d-flex justify-content-between btn btn-link align-items-center">
                          <span
                            id="retainerTxnId"
                            className="mx-auto text-center"
                          >
                            {retainerTxnId}
                          </span>
                          <em
                            onClick={() => copyTxnId("retainerTxnId")}
                            className={`icon ni ${
                              copiedTxnId === "retainerTxnId"
                                ? "ni-check"
                                : "ni-copy"
                            }`}
                          ></em>
                        </td>
                        <td className="text-center">{retainerPaidDate}</td>
                        <td className="d-flex justify-content-center">
                          <div
                            onClick={() => {
                              setActivetab("6");
                              setIsTotalInvoice(false);
                              setIsShowSingleInvoice("retainer");
                            }}
                          >
                            <span className="view-text text-center fs-6 text-dark text-primary">
                              View
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                    {schedulePay2TxnId && (
                      <tr className="fw-normal">
                        <td className="text-center">scheduled Payment 2 </td>
                        <td className="text-center">${scheduledPayment2}</td>
                        <td className="text-center">
                          {scheduled_payment_2_mode == "1"
                            ? "Stripe"
                            : scheduled_payment_2_mode == "3"
                            ? " Paypal"
                            : "Pending"}
                        </td>
                        <td className="d-flex justify-content-between btn btn-link align-items-center">
                          <span
                            id="schedulePay2TxnId"
                            className="mx-auto text-center"
                          >
                            {schedulePay2TxnId}
                          </span>
                          <em
                            onClick={() => copyTxnId("schedulePay2TxnId")}
                            className={`icon ni ${
                              copiedTxnId === "schedulePay2TxnId"
                                ? "ni-check"
                                : "ni-copy"
                            }`}
                          ></em>
                        </td>
                        <td className="text-center">{scheduled2PaidDate}</td>
                        <td className="d-flex justify-content-center">
                          <div
                            onClick={() => {
                              setActivetab("6");
                              setIsTotalInvoice(false);
                              setIsShowSingleInvoice("scheduled1");
                            }}
                          >
                            <span className=" view-text text-center fs-6 text-dark text-primary">
                              View
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                     {schedulePay3TxnId && (
                      <tr className="fw-normal">
                        <td className="text-center"> scheduled Payment 3</td>
                        <td className="text-center">${scheduledPayment3}</td>
                        <td className="text-center">
                          {scheduled_payment_3_mode === "1"
                            ? "Stripe"
                            : scheduled_payment_3_mode === "3"
                            ? "Paypal"
                            : "Pending"}
                        </td>
                        <td className="d-flex justify-content-between btn btn-link align-items-center">
                          <span
                            id="schedulePay3TxnId"
                            className="mx-auto text-center"
                          >
                            {schedulePay3TxnId}
                          </span>
                          <em
                            onClick={() => copyTxnId("schedulePay3TxnId")}
                            className={`icon ni ${
                              copiedTxnId === "schedulePay3TxnId"
                                ? "ni-check"
                                : "ni-copy"
                            }`}
                          ></em>
                        </td>
                        <td className="text-center">{scheduled3PaidDate}</td>
                        <td className="d-flex justify-content-center">
                          <div
                            onClick={() => {
                              setActivetab("6");
                              setIsTotalInvoice(false);
                              setIsShowSingleInvoice("scheduled2");
                            }}
                          >
                            <span className="view-text text-center fs-6 text-dark text-primary">
                              View
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                    {/* Repeat for other payments */}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="alert alert-warning" role="alert">
              <p className="mb-0">
                <strong>Hey {clientName},</strong> you haven't made any payments
                yet. Make sure you complete all payments before the{" "}
                {client?.[activeVariable]?.sectin1?.map((item) => item?.title4)}{" "}
                date ({eventdate}).
              </p>
            </div>
          )}
        </Block>
        <Button
          className="px-3 my-2  btn-primary"
          color="primary"
          onClick={() => navigate("/my-events")}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span> Back</span>
        </Button>
      </Content>
    </React.Fragment>
  );
};
export default TransactionDetails;
