import React, { useContext, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { DataContext } from "../../../../context/DataProvider.jsx";
import { getCookie } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router";
import { Button } from "reactstrap";
import { Icon } from "../../../../components/Component.js";
import { useQueryClient } from "react-query";
import { QueryClient } from "react-query";

function CheckoutForm({
  selectedPlan,
  setModalData,
  invalidPlanData,
  setModal,
  modal,
  fetchPlanData,
  setModalTab,
  modalTab,
  contact_number,
}) {
  const { newPlanId, transactionid, setTransactionId } =
    useContext(DataContext);
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();

  const eventValue = Cookies.get("EventVal");
  const paymentType = Cookies.get("paymentType");
  const payerId = Cookies.get("payerId");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${process.env.REACT_APP_API_URL}plan-selection`,
          payment_method_data: {
            billing_details: {
              name: getCookie("username"),
              email: getCookie("email"),
            },
          },
        },
        redirect: "if_required",
      });

      if (error) {
        setMessage(error.message);
        toast.error(error.message);
      } else if (paymentIntent?.status === "succeeded") {
        setTransactionId(paymentIntent.id);
        await handleSubmitSubscribePayment(
          paymentIntent.id,
          id,
          contact_number
        );
      }
    } catch (err) {
      toast.error("An error occurred during payment processing.");
      console.error("Error in payment processing:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitSubscribePayment = async (
    transactionId,
    id,
    contactNumber
  ) => {
    const note =
      paymentType === "1"
        ? "Retainer Payment"
        : paymentType === "2"
        ? "Scheduled 2 Payment"
        : "Scheduled 3 Payment";

    try {
      const formData = new FormData();
      formData.append("payment_txn_id", transactionId);
      formData.append("event_id", id);
      formData.append("payment_for", paymentType);
      formData.append("note", note);
      formData.append("mode", 1);
      formData.append("payer_id", payerId);
      formData.append("payment_response", JSON.stringify({}));
      formData.append("amount", eventValue);
      formData.append("status", 3);
      formData.append("purpose", 2);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/client/event-payment`,
        {
          method: "POST",
          body: formData,
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );

      const result = await response.json();

      if (response.ok) {
        // const number = "+91" + contactNumber;
        // const message = `Congratulations! ${note} of value $${eventValue} done successfully`;
        Swal.fire({
          icon: "success",
          title: `${note} Done Successfully`,
          focusConfirm: false,
          confirmButtonText: "OK",
        }).then((result) => {
          // Check if "OK" button was clicked
          if (result.isConfirmed) {
            navigate(`/my-events/event-details/${id}`);
          }
        });

        queryClient.invalidateQueries("get-notifications");
        // Cookies.remove("EventVal");
        // Cookies.remove("paymmnetType");
      } else {
        toast.error(result?.message || "Failed to submit payment.");
      }
    } catch (error) {
      //toast.error("An error occurred during payment submission.");
      console.error("Error during payment submission:", error);
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <div className="d-flex justify-content-center mt-3">
          <Button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className="btn-primary"
          >
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay Now"
              )}
            </span>
          </Button>
        </div>
        {message && <div id="payment-message">{message}</div>}

        <Button
          className="px-3 my-2 btn-primary"
          color="primary"
          onClick={() => navigate(`/my-events/event-details/${id}`)}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span> Back</span>
        </Button>
      </form>
    </>
  );
}

export default CheckoutForm;
