import React, { useState, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import { Card, Col, Row } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../../components/Component";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  formatTime,
  getActiveEnvironmentVariable,
} from "../../../../utils/Utils";
import client from "../../../../client.json";

const Information = ({
  upcomingEvent,
  guestCount,
  eventName,
  eventType,
  eventdate,
  setUpTime,
  startTime,
  endTime,
  packagePrice,
  packageName,
  address,
  zipCode,
  city,
}) => {
  const navigate = useNavigate();
  const activeVariable = getActiveEnvironmentVariable();
  //manage booking status from value
  let bookingStatus = "";

  if (upcomingEvent?.data[0]?.booking_request_status === "0") {
    bookingStatus = "Pending";
  }
  if (upcomingEvent?.data[0]?.booking_request_status === "1") {
    bookingStatus = "Accept";
  }
  if (upcomingEvent?.data[0]?.booking_request_status === "2") {
    bookingStatus = "Cancel";
  }
 

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent className="p-0 m-0">
              <BlockTitle tag="h4">{eventName}</BlockTitle>
              <BlockDes>
                <p className="fs-6">
                  "Explore all the key information about the{" "}
                  {client?.[activeVariable]?.sectin1?.map(
                    (item) => item?.title4
                  )}{" "}
                  , from schedule to venue."
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none"></BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="p-2">
            <Col md={6} className="border p-3 bg-white">
              <div className="nk-data data-list">
                <div className="data-head">
                  <h6 className="overline-title"> Date And Time </h6>
                </div>
                <div className=" table-responsive my-3">
                  <table className="table table-bordered mb-0">
                    <tbody>
                      <tr className="fw-normal">
                        <td className="text-center">
                          {client?.[activeVariable]?.sectin1?.map(
                            (item) => item?.title3
                          )}{" "}
                          Date
                        </td>
                        <td className="text-center">{eventdate}</td>
                      </tr>
                      {setUpTime? (
                        <tr className="fw-normal">
                          <td className="text-center">Setup Time</td>
                          <td className="text-center">{setUpTime}</td>
                        </tr>
                      ):(
                        <tr className="fw-normal">
                        <td className="text-center">Setup Time</td>
                        <td className="text-center">-----</td>
                      </tr>
                      )
                      }

                      <tr className="fw-normal">
                        <td className="text-center">Start Time</td>
                        <td className="text-center">{startTime}</td>
                      </tr>
                      <tr className="fw-normal">
                        <td className="text-center">End Time</td>
                        <td className="text-center">{endTime}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="nk-data data-list">
                <div className="data-head">
                  <h6 className="overline-title"> Other Details</h6>
                </div>
                <div className="my-3">
                  <table className="table table-bordered mb-0">
                    <tbody>
                      {upcomingEvent?.data[0]?.booking_status && (
                        <tr className="fw-normal">
                          <td className="text-center">Booking Status</td>
                          <td className="text-center">{bookingStatus}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {packagePrice && (
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title"> Package </h6>
                  </div>
                  <div className="my-3">
                    <table className="table table-bordered mb-0  table-responsive">
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">
                            Package
                          </th>
                          <th className="text-center" scope="col">
                            value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="fw-normal">
                          <td className="text-center">{packageName} </td>
                          <td className="text-center">${packagePrice}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </Col>
            <Col md={6} className="border p-3 bg-white">
              <div className="nk-data data-list">
                <div className="data-head">
                  <h6 className="overline-title"> Description</h6>
                </div>
                <div className="my-3">
                  <table className="table table-bordered mb-0">
                    <tbody>
                      <tr className="fw-normal">
                        <td className="text-center">
                          {client?.[activeVariable]?.sectin1?.map(
                            (item) => item?.title3
                          )}{" "}
                          Type
                        </td>
                        <td className="text-center">{eventType}</td>
                      </tr>
                      <tr className="fw-normal">
                        <td className="text-center">
                          {" "}
                          {client?.[activeVariable]?.sectin1?.map(
                            (item) => item?.title3
                          )}{" "}
                          Name
                        </td>
                        <td className="text-center">{eventName}</td>
                      </tr>
                      {guestCount && (
                        <tr className="fw-normal">
                          <td className="text-center">Guest Count</td>
                          <td className="text-center">{guestCount}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {city && (
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title"> Venue</h6>
                  </div>
                  <div className="my-3">
                    <table className="table table-bordered mb-0">
                      <tbody>
                        <tr className="fw-normal">
                          <td className="text-center">Address</td>
                          <td className="text-center">{address}</td>
                        </tr>
                        <tr className="fw-normal">
                          <td className="text-center">City</td>
                          <td className="text-center">{city}</td>
                        </tr>
                        <tr className="fw-normal">
                          <td className="text-center">Zip</td>
                          <td className="text-center">{zipCode}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Block>

        <Button
          className="px-3 my-2 btn-primary"
          color="primary"
          onClick={() => navigate("/my-events")}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span> Back</span>
        </Button>
      </Content>
    </React.Fragment>
  );
};

export default Information;
