import React, { useState, useEffect } from "react";
import {
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  BlockDes,
  BlockHeadContent,
  Block,
  BlockBetween,
} from "../../../../../components/Component";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import "../../../../../css/style.css";
import DJLogo from "../../../../../images/Party DJ Mix.png";
import { invoiceData } from "./InvoiceData";
import { useParams, Link, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Invoice = ({
  clientName,
  clientLastName,
  username,
  address,
  contactNumber,
  invoiceId,
  packagePrice,
  packageDuration,
  startTime,
  endTime,
  addOnTotal,
  InvoiceTravelFee,
  overtimeRateHourly,
  discount1,
  discount2,
  totalFee,
  isTotalInvoice,
  isShowSingleInvoice,
  retainerTxnId,
  schedulePay2TxnId,
  schedulePay3TxnId,
  retainerPrice,
  balanceDueAfterRetainer,
  scheduledPayment2,
  scheduledPayment3,
  retainerPaidDate,
  scheduled2PaidDate,
  scheduled3PaidDate,
}) => {
  const { id } = useParams();
  console.log("balanceDueAfterRetainer from invoice:", balanceDueAfterRetainer);

  const [data] = useState(invoiceData);
  const [user, setUser] = useState();

  let { eventid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const IdDemo = eventid;
    if (IdDemo !== undefined || null || "") {
      let spUser = data.find((item) => item.IdDemo === Number(IdDemo));
      setUser(spUser);
    } else {
      setUser(data[0]);
    }
  }, [eventid, data]);

  //Download invoice....
  const handleDownload = async () => {
    const invoiceElement = document.querySelector(".invoice");
    if (!invoiceElement) {
      console.error("Invoice element not found!");
      return;
    }

    try {
      const canvas = await html2canvas(invoiceElement, {
        scale: 1.5,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("portrait", "pt", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice-${user.orderId || "default"}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  // Calculate total minutes between start and end times
  const calculateMinutesBetweenTimes = (stime, etime) => {
    if (!stime || !etime) return 0;

    const convertToMinutes = (time) => {
      const [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) hours += 12;
      if (modifier === "AM" && hours === 12) hours = 0;

      return hours * 60 + minutes;
    };

    const startTotalMinutes = convertToMinutes(stime);
    const endTotalMinutes = convertToMinutes(etime);
    const differenceInMinutes = Math.abs(endTotalMinutes - startTotalMinutes);

    console.log("Difference in Minutes:", differenceInMinutes);
    return differenceInMinutes;
  };

  const timedata = calculateMinutesBetweenTimes(startTime, endTime) / 60;
  const remaining = balanceDueAfterRetainer - scheduledPayment2;
  console.log("balance  :", remaining);
  return (
    <React.Fragment>
      <Head title="Invoice Detail"></Head>
      {user && (
        <Content>
          <BlockHead>
            <BlockBetween className="g-3">
              <BlockHeadContent>
                <BlockTitle tag="h4">Event Invoice </BlockTitle>

                <BlockDes>
                  <p className="fs-6">
                    "Download and manage your invoices quickly and securely."
                  </p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                {/* <Link to={`${process.env.PUBLIC_URL}/invoice-list`}>
                  <Button
                    color="light"
                    outline
                    className="btn-icon bg-white d-inline-flex d-sm-none"
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </Link> */}
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block className="custom-class">
            <div className="invoice border">
              <div className="row d-flex justify-content-between align-items-center p-3">
                <div className="col-lg-4">
                  <img
                    src={DJLogo}
                    alt="Party DJ Mix Logo"
                    className="img-fluid"
                    style={{ width: "200px" }}
                  />
                </div>

                <div className="col-lg-4">
                  <h3 className="text-center">Invoice </h3>
                </div>

                <div className="col-lg-4 d-flex justify-content-end">
                  <Button
                    size="lg"
                    color="primary"
                    outline
                    className="btn-icon btn-white btn-dim me-2"
                    onClick={handleDownload}
                  >
                    <em className="icon ni ni-download fs-2"></em>
                  </Button>
                  {/* <Button
                    size="lg"
                    color="primary"
                    outline
                    className="btn-icon btn-white btn-dim"
                    // onClick={() =>
                    //   navigate(
                    //     "/my-events/payment-details/Invoice/Invoice-Print"
                    //   )
                    // }
                     onClick={() => window.print()}
                  >
                    <Icon name="printer-fill"></Icon>
                  </Button> */}
                </div>
              </div>

              <div className="invoice-wrap d-flex flex-column border-0">
                <div className="invoice-head">
                  <div className="invoice-contact">
                    <span className="overline-title fs-6">Invoice To</span>
                    <div className="invoice-contact-info">
                      <h4 className="title">
                        {clientName
                          ? `${clientName} ${clientLastName}`
                          : username}
                      </h4>

                      <ul className="list-plain">
                        {address ? (
                          <li>
                            <Icon name="map-pin-fill"></Icon>
                            <span>{address}</span>
                          </li>
                        ) : (
                          ""
                        )}
                        {contactNumber ? (
                          <li>
                            <Icon name="call-fill"></Icon>
                            <span>{contactNumber}</span>
                          </li>
                        ) : (
                          "null"
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="invoice-desc">
                    <ul className="list-plain">
                      <li className="invoice-id">
                        <span>Invoice ID</span>:<span>{invoiceId}</span>
                      </li>
                      <li className="invoice-id">
                        <span>Event ID</span>:<span>{id}</span>
                      </li>

                      <li className="invoice-date">
                        <span>Date</span>:
                        <span>
                          {new Date().getDate().toString().padStart(2, "0")}-
                          {(new Date().getMonth() + 1)
                            .toString()
                            .padStart(2, "0")}
                          -{new Date().getFullYear()}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-bills">
                  <div className="nk-data data-list">
                    <div className="data-head">
                      <h6 className="overline-title">Invoice</h6>
                    </div>
                    <div className="my-3 table-responsive">
                      <table className="table table-bordered ">
                        <thead>
                          <tr>
                            <th scope="col">Item</th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {packagePrice && (
                            <tr className="fw-light">
                              <td>Demo Package</td>
                              <td>
                                {packageDuration === "h"
                                  ? `${packagePrice} X ${timedata} = ${
                                      packagePrice * timedata
                                    }`
                                  : `$${packagePrice}`}
                              </td>
                            </tr>
                          )}
                          {addOnTotal && (
                            <tr className="fw-light">
                              <td>Addon Total</td>
                              <td>${addOnTotal}</td>
                            </tr>
                          )}
                          {overtimeRateHourly !== 0 && packageDuration === "d" && (
                            <tr>
                              <td> Overtime Total</td>
                              <td>${overtimeRateHourly}</td>
                            </tr>
                          )}
                          {InvoiceTravelFee !== 0 && packageDuration === "d" && (
                            <tr>
                              <td>Travel Fee </td>
                              <td>${InvoiceTravelFee}</td>
                            </tr>
                          )}
                          {discount1 !== 0 && (
                            <tr>
                              <td> Discount 1</td>
                              <td>${discount1}</td>
                            </tr>
                          )}
                          {discount2 !== 0 && (
                            <tr>
                              <td>Discount 2</td>
                              <td>${discount2}</td>
                            </tr>
                          )}

                          {totalFee && (
                            <tr>
                              <td>Total</td>
                              <td>${totalFee}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="data-head">
                    <h6 className="overline-title">Transaction Details</h6>
                  </div>
                  <div className="table-responsive my-3">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th className="text-center fs-6" scope="col">
                            Payment Type
                          </th>
                          <th className="text-center fs-6" scope="col">
                            Amount
                          </th>
                          <th className="text-center fs-6" scope="col">
                            Txn ID
                          </th>
                          {!isTotalInvoice && (
                            <th className="text-center fs-6" scope="col">
                              REMAINING AMOUNT
                            </th>
                          )}
                          <th className="text-center fs-6" scope="col">
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isTotalInvoice ? (
                          // Show all payments when isTotalInvoice is true
                          <>
                            {retainerTxnId && (
                              <tr className="fw-normal align-baseline">
                                <td className="text-center">Retainer</td>
                                <td className="text-center">
                                  ${retainerPrice}
                                </td>
                                <td className="text-center">{retainerTxnId}</td>
                                <td className="text-center">
                                  {retainerPaidDate}
                                </td>
                              </tr>
                            )}
                            {schedulePay2TxnId && (
                              <tr className="fw-normal align-baseline">
                                <td className="text-center">
                                  Scheduled Payment 2
                                </td>
                                <td className="text-center">
                                  ${scheduledPayment2}
                                </td>
                                <td className="text-center">
                                  {schedulePay2TxnId}
                                </td>
                                <td className="text-center">
                                  {scheduled2PaidDate}
                                </td>
                              </tr>
                            )}
                            {schedulePay3TxnId && (
                              <tr className="fw-normal align-baseline">
                                <td className="text-center">
                                  Scheduled Payment 3
                                </td>
                                <td className="text-center">
                                  ${scheduledPayment3}
                                </td>
                                <td className="text-center">
                                  {schedulePay3TxnId}
                                </td>
                                <td className="text-center">
                                  {scheduled3PaidDate}
                                </td>
                              </tr>
                            )}
                          </>
                        ) : (
                          // Show payments based on isShowSingleInvoice when isTotalInvoice is false
                          (() => {
                            const rows = [];
                            if (isShowSingleInvoice === "retainer") {
                              rows.push(
                                <tr
                                  className="fw-normal align-baseline"
                                  key="retainer"
                                >
                                  <td className="text-center">Retainer</td>
                                  <td className="text-center">
                                    ${retainerPrice}
                                  </td>
                                  <td className="text-center">
                                    {retainerTxnId}
                                  </td>
                                  <td className="text-center">
                                    {balanceDueAfterRetainer}
                                  </td>
                                  <td className="text-center">
                                    {retainerPaidDate}
                                  </td>
                                </tr>
                              );
                            } else if (isShowSingleInvoice === "scheduled1") {
                              rows.push(
                                <tr
                                  className="fw-normal align-baseline"
                                  key="scheduled1"
                                >
                                  <td className="text-center">
                                    Scheduled Payment 2
                                  </td>
                                  <td className="text-center">
                                    ${scheduledPayment2}
                                  </td>
                                  <td className="text-center">
                                    {schedulePay2TxnId}
                                  </td>
                                  <td className="text-center">
                                    {balanceDueAfterRetainer -
                                      scheduledPayment2}
                                  </td>
                                  <td className="text-center">
                                    {scheduled2PaidDate}
                                  </td>
                                </tr>
                              );
                            } else if (isShowSingleInvoice === "scheduled2") {
                              rows.push(
                                <tr
                                  className="fw-normal align-baseline"
                                  key="scheduled2"
                                >
                                  <td className="text-center">
                                    Scheduled Payment 3
                                  </td>
                                  <td className="text-center">
                                    ${scheduledPayment3}
                                  </td>
                                  <td className="text-center">
                                    {schedulePay3TxnId}
                                  </td>
                                  <td className="text-center">0</td>
                                  <td className="text-center">
                                    {scheduled3PaidDate}
                                  </td>
                                </tr>
                              );
                            } else {
                              rows.push(
                                <tr
                                  className="fw-normal align-baseline"
                                  key="retainer"
                                >
                                  <td className="text-center">Retainer</td>
                                  <td className="text-center">
                                    ${retainerPrice}
                                  </td>
                                  <td className="text-center">
                                    {retainerTxnId}
                                  </td>
                                  <td className="text-center">
                                    {balanceDueAfterRetainer}
                                  </td>
                                  <td className="text-center">-------</td>
                                </tr>,
                                <tr
                                  className="fw-normal align-baseline"
                                  key="scheduled1"
                                >
                                  <td className="text-center">
                                    Scheduled Payment 2
                                  </td>
                                  <td className="text-center">
                                    ${scheduledPayment2}
                                  </td>
                                  <td className="text-center">
                                    {schedulePay2TxnId}
                                  </td>
                                  <td className="text-center">
                                    {balanceDueAfterRetainer -
                                      scheduledPayment2}
                                  </td>
                                  <td className="text-center">
                                    {scheduled2PaidDate}
                                  </td>
                                </tr>,
                                <tr
                                  className="fw-normal align-baseline"
                                  key="scheduled2"
                                >
                                  <td className="text-center">
                                    Scheduled Payment 3
                                  </td>
                                  <td className="text-center">
                                    ${scheduledPayment3}
                                  </td>
                                  <td className="text-center">
                                    {schedulePay3TxnId}
                                  </td>
                                  <td className="text-center">-------</td>
                                  <td className="text-center">
                                    {scheduled3PaidDate}
                                  </td>
                                </tr>
                              );
                            }
                            return rows;
                          })()
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Block>

          <Button
            className="px-3 my-2 btnprimary"
            color="primary"
            onClick={() => navigate("/my-events")}
          >
            <Icon className="ni-arrow-long-left"></Icon>
            <span> Back</span>
          </Button>
        </Content>
      )}
    </React.Fragment>
  );
};

export default Invoice;
