import React, { useEffect } from "react";
import { Icon, ReactDataTable } from "../../../components/Component";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "react-query";
import { getPastEventsList } from "../../../http/getApi";
import { ShimmerTable } from "react-shimmer-effects";
import { formatDate } from "../../../utils/Utils";
import { getActiveEnvironmentVariable } from "../../../utils/Utils";
import client from "../../../client.json";
import { CardTitle } from "reactstrap";

export const NotAvailable = () => (
  <div style={{ textAlign: "center" }}>----</div>
);

const PastEvents = () => {
  const navigate = useNavigate();
  const [recordLimit, setRecordLimit] = useState(5);
  const [pageNo, setPageNo] = useState(0);
  const [search, setSearch] = useState("");
  const activeVariable = getActiveEnvironmentVariable();
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  //fetching event data
  const {
    data: pastEventsList,
    isLoading: pastEventsLoading,
    isError: pastEventsError,
  } = useQuery({
    queryKey: ["get-past-events-list", recordLimit, pageNo, search],
    queryFn: () =>
      getPastEventsList({
        id: "",
        pageNo,
        recordLimit,
        search: search,
        categorize_event: "history",
      }),
  });

  useEffect(() => {
    if (pastEventsList?.totalRecords) {
      setTotalRecords(pastEventsList.totalRecords);
    }
  }, [pastEventsList, setTotalRecords]);

  console.log("pastEventsList:", pastEventsList);
  const tableHeaddings = [
    {
      name: "SR no.",
      selector: (row, index) => pageNo * recordLimit + index + 1,
      maxWidth: "5px",
    },
    {
      name: "Event Name",
      selector: (row) => row.event_name || <NotAvailable />,
    },
    {
      name: "Event Type",
      selector: (row) => row.event_type_name || <NotAvailable />,
    },
    {
      name: "Event Date",
      selector: (row) => formatDate(row.start_date_time) || <NotAvailable />,
    },

    {
      name: "Package Name",
      selector: (row) =>
        row.package_details[0]?.package_name || <NotAvailable />,
    },
    {
      name: "Venue Name",
      selector: (row) =>
        row.venue_details[0]?.address
          ? row.venue_details[0]?.address
          : "-------",
    },
  ];

  return (
    <React.Fragment>
      <div className="border rounded">
        <div className="card-inner">
          <div className="card-title-group">
            <CardTitle>
              <h6 className="title">
                <h4 className="mb-3">
                  Past{" "}
                  {client?.[activeVariable]?.sectin1?.map(
                    (item) => item?.title1
                  )}
                </h4>
              </h6>
            </CardTitle>
          </div>
        </div>
        <div>
          <label>
            <input
              type="search"
              className="ms-2 mb-1 form-control form-control-sm w-250px border-2"
              placeholder="Search..."
              onChange={(ev) => {
                setSearch(ev.target.value);
              }}
            />
          </label>
        </div>

        {pastEventsLoading ? (
          <div>
            <ShimmerTable row={5} />
          </div>
        ) : (
          <div className="p-2">
            <ReactDataTable
              data={pastEventsList?.data || []}
              columns={tableHeaddings}
              expandableRows
              actions
              searchBy="event_name"
              pageNo={setPageNo}
              totalRecords={totalRecords}
              activePage={pageNo || 0}
              //loading={loading}
              setSearch={setSearch}
              recordLimit={recordLimit}
              setRecordLimit={setRecordLimit}
              pagination
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default PastEvents;
