import Slider from "react-slick";
import {
  SlickArrowLeft,
  SlickArrowRight,
} from "../../../components/partials/slick/SlickComponents";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import EventCard from "../../home/components/EventCard";
import { getUpcomingEventsList } from "../../../http/getApi";
import { Button } from "reactstrap";
import { useNavigate } from "react-router";
import {
  ShimmerCard,
  ShimmerTable,
  ShimmerThumbnail,
} from "react-shimmer-effects";
import Cookies from "js-cookie";
import client from "../../../client.json";
import { getActiveEnvironmentVariable } from "../../../utils/Utils";

export const NotAvailable = () => (
  <div style={{ textAlign: "center" }}>----</div>
);

const UpcomingEvents = () => {
  const navigate = useNavigate();
  const userName = Cookies.get("username");

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const activeVariable = getActiveEnvironmentVariable();
  const [recordLimit, setRecordLimit] = useState(5);
  function OpenPlanEvent() {
    navigate("/my-events/plan-event");
  }

  const settings = {
    className: "slider-init plan-list",
    // slidesToShow: 3,
    centerMode: false,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      { breakpoint: 1539, settings: { slidesToShow: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
    slide: "li",
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    dots: true,
  };

  //fetching event data
  const {
    data: upcomingEventsList,
    isLoading: upcomingEventsLoading,
    isError: upcomingEventsListError,
  } = useQuery({
    queryKey: ["get-upcoming-events-list"],
    queryFn: () =>
      getUpcomingEventsList({
        id: "",
        page: "",
        search: "",
        category: "upcoming",
        booking_request_status: "1",
      }),
  });


  useEffect(() => {
    if (upcomingEventsList?.data?.length > 0) {
      setUpcomingEvents(upcomingEventsList?.data);
    }
  }, [upcomingEventsList]);

  return (
    <section className="p-3">
      {upcomingEventsLoading ? (
        <div className="text-center">
          <ShimmerThumbnail
            height={300}
            width={700}
            rounded
            className="bg-light"
          />
        </div>
      ) : upcomingEvents && upcomingEvents.length > 0 ? (
        <div>
          <h4 className="mx-2 my-2">
             Upcoming{" "}
            {client?.[activeVariable]?.sectin1?.map((item) => item?.title1)}
          </h4>
          <div
            className="d-flex d-flex justify-center"
            style={{ flexWrap: "wrap" }}
          >
            <div className="w-50 d-block justify-content-center">
              <Slider {...settings}>
                {upcomingEvents
                  .map((event, index) => (
                    <EventCard
                      key={index}
                      eventType={event.event_type_name}
                      eventName={event.event_name}
                      eventDate={event.start_date_time}
                      VenueName={
                        event?.venue_details?.[0]?.venue_name || (
                          <NotAvailable />
                        )
                      }
                      VenueCity={
                        event?.venue_details?.[0]?.city_name || <NotAvailable />
                      }
                      VenueState={
                        event?.venue_details?.[0]?.state_name || (
                          <NotAvailable />
                        )
                      }
                      VenueCountry={
                        event?.venue_details?.[0]?.country_name || (
                          <NotAvailable />
                        )
                      }
                      id={event?.id}
                    />
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h3 className="mb-4">
            No Upcoming{" "}
            {client?.[activeVariable]?.sectin1?.map((item) => item?.title1)}{" "}
            Scheduled
          </h3>
          <p className="lead mb-4">
            Hey, {userName ? userName : "Sourabh"}, it looks like there are no
            upcoming{" "}
            {client?.[activeVariable]?.sectin1?.map((item) => item?.title2)}{" "}
            scheduled at the moment.
          </p>
        </div>
      )}
    </section>
  );
};
export default UpcomingEvents;
