import React, { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import CopyToClipboard from "react-copy-to-clipboard";
import { Col, Modal, ModalBody, Row, Button } from "reactstrap";
import {
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTablePagination,
  DataTableRow,
} from "../Component";
import NOResult from "../../images/No Result.png";
import "../../css/style.css";
import { ShimmerTable } from "react-shimmer-effects";
import Noreult1 from "../../images/placeholderimageforallproject.jpg";
import { getActiveEnvironmentVariable } from "../../utils/Utils";




const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input
      id={rest.name}
      type="checkbox"
      className="custom-control-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="custom-control-label" htmlFor={rest.name} />
  </div>
));

const ReactDataTable = ({
  data,
  columns,
  pagination,
  actions,
  className,
  selectableRows,
  expandableRows,
  searchBy,
  totalRecords,
  pageNo,
  loading,
  activePage,
  recordLimit,
  setSearch,
  normalSearch,
  setRecordLimit,
}) => {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPageS, setRowsPerPage] = useState(recordLimit);
  const [mobileView, setMobileView] = useState();

  useEffect(() => {
    let defaultData = tableData;
    if (searchText !== "") {
      defaultData = data.filter((item) => {
        return Object.values(item).some((value) => {
          const sanitizedValue = value !== null ? value.toString() : "";
          return sanitizedValue
            .toLowerCase()
            .includes(searchText.toLowerCase());
        });
      });
      setTableData(defaultData);
    } else {
      setTableData(data);
    }
  }, [searchText, data, searchBy]);

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, [expandableRows]);
  const activeVariable = getActiveEnvironmentVariable();

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value);

    // Only update state if the new value is different from the previous one
    if (newRowsPerPage !== rowsPerPageS) {
      setRowsPerPage(newRowsPerPage);

      // Update setRecordLimit only if it's provided
      if (setRecordLimit) {
        setRecordLimit(newRowsPerPage);
      }
    }
  };

  return (
    <div
      className={`dataTables_wrapper dt-bootstrap4 no-footer ${
        className ? className : ""
      }`}
    >
     
      <div className="datatable-filter">
        <div className="showdiv g-2">
        
          <div
            className="Reactdatatable dataTables_length "
            id="DataTables_Table_0_length"
          >
            <label>
              <span className="d-none d-sm-inline-block">Show</span>
              <div className="form-control-select d-flex justify-content-sm-start">
                <select
                  name="DataTables_Table_0_length"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  onChange={handleRowsPerPageChange}
                  value={rowsPerPageS}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              </div>
            </label>
          </div>
        </div>
      </div>
     

      <DataTable
        data={tableData?.length > 0 ? tableData : []}
        columns={columns}
        className={className}
        selectableRows={selectableRows}
        selectableRowsComponent={CustomCheckbox}
       
        noDataComponent={
          <div className="p-2">
            <img
              src={activeVariable == "CRM" ? NOResult : Noreult1}
              height={100}
            />
            <div className="text-dark py-2">There are no record found</div>
          </div>
        }
        // sortIcon={
        //   <div>
        //     <span>&darr;</span>
        //     <span>&uarr;</span>
        //    </div>
        // }
        pagination={pagination}
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
        paginationPerPage={rowsPerPageS}
        paginationComponent={(props) => {
          const handleChangePage = (payload) => {
            if (pageNo) pageNo(payload - 1);
            props.onChangePage(payload);
          };

          const handleChangeRowsPerPage = (payload) => {
            if (setRecordLimit) setRecordLimit(payload);
            props.onChangeRowsPerPage(payload);
          };

          return (
            <DataTablePagination
              customItemPerPage={rowsPerPageS}
              itemPerPage={props.rowsPerPage}
              totalItems={totalRecords || props.rowCount}
              paginate={handleChangePage}
              currentPage={parseInt(activePage) + 1 || props.currentPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              setRowsPerPage={setRowsPerPage}
              changePage={pageNo}
            />
          );
        }}
      ></DataTable>
    </div>
  );
};

export default ReactDataTable;
