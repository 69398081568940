import React from "react";
import ProfileImage from "../../assets/images/profileimg.png";
import homePageImg from "../../assets/images/chatNew.png";
import Cookies from "js-cookie";

const WelcomePage = () => {
  const Username = Cookies.get("username");
  const ProfilePic = Cookies.get("profile_image");

  return (
    <div className="d-flex flex-column py-5 vh-100 text-center ">
      <div className="container p-4">
        <div className="d-flex align-items-center  mb-4">
          <img
            src={ProfilePic || ProfileImage}
            alt="Profile"
            className="rounded-circle me-3 border border-light shadow"
            width="60"
            height="60"
          />
          <div className="text-start">
            <h2 className="fw-medium">Welcome, {Username} !</h2>
          </div>
        </div>

        <p className="lead mt-3">
          Start connecting with your favorite DJs and other music lovers on{" "}
          <b>ipartydjmix</b>. Plan your next event seamlessly!
        </p>

        <div>
          <div>
            <img src={homePageImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
