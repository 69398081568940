import React, { useEffect, useState } from "react";
import Content from "../../../../layout/content/Content";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { Button, Table } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../components/Component";
import client from "../../../../client.json";
import { getActiveEnvironmentVariable } from "../../../../utils/Utils";


const PaymentDetails = ({
  package_amount,
  packagePrice,
  packageDuration,
  retainerPrice,
  retainerTxnId,
  schedulePay2TxnId,
  schedulePay3TxnId,
  addOnTotal,
  InvoiceTravelFee,
  overtimeRateHourly,
  discount1,
  discount2,
  balanceDueAfterRetainer,
  totalFee,
  startTime,
  endTime,
  scheduledPayment2,
  scheduledPayment3,
  scheduledPayment2Date,
  scheduledPayment3Date,
  eventFinancials,
  userId,
  paymentDetails,
  paymentDetailsLoading,
  clientName,
  eventdate,
  retainer_mode,
  scheduled_payment_2_mode,
  scheduled_payment_3_mode,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const activeVariable = getActiveEnvironmentVariable();
  // const [retainerPaymentStatus, setRetainerPaymentStatus] = useState("pending");
  // const [scheduledPay2Mode, setScheduledPay2Mode] = useState("pending");
  // const [scheduledPay2Status, setScheduledPay2Status] = useState("pending");
  // const [scheduledPay3Mode, setScheduledPay3Mode] = useState("pending");
  // const [scheduledPay3Status, setScheduledPay3Status] = useState("pending");

  const [retainerButtonText, setRetainerButtonText] = useState("Pay");
  const [scheduled2ButtonText, setScheduled2ButtonText] = useState("Pay");
  const [scheduled3ButtonText, setScheduled3ButtonText] = useState("Pay");
  
  function OpenMakePayment(paymentFor, value, buttonText) {
    if (buttonText === "Pay") {
      navigate(`/my-events/make-payment/${id}`);
      Cookies.set("EventVal", value);
      Cookies.set("paymentType", paymentFor);
      Cookies.set("payerId", userId);
      console.log("paymentType:=", paymentFor);
    }
  }

  useEffect(() => {
    if (paymentDetails?.data?.done_payment?.retainer_payment !== undefined) {
      setRetainerButtonText("Paid");
    }
    if (paymentDetails?.data?.done_payment?.scheduled_payment_2 !== undefined) {
      setScheduled2ButtonText("Paid");
      // setScheduledPay2Status("");
    }
    if (paymentDetails?.data?.done_payment?.scheduled_payment_3 !== undefined) {
      setScheduled3ButtonText("Paid");
      // setScheduledPay3Status("");
    }
  }, [paymentDetails?.data?.done_payment]);

  // calculate minutes...
  function calculateMinutesBetweenTimes(stime, etime) {
    const start = stime;
    const end = etime;

    function convertToMinutes(time) {
      const [timePart, modifier] = time.split(" ");
      let [hours, minutes] = timePart.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      }
      if (modifier === "AM" && hours === 12) {
        hours = 0;
      }
      return hours * 60 + minutes;
    }

    const startTotalMinutes = convertToMinutes(start);
    const endTotalMinutes = convertToMinutes(end);
    const differenceInMinutes = Math.abs(endTotalMinutes - startTotalMinutes);
    const TotalMinutes = differenceInMinutes / 60;
    console.log("Minutes:", differenceInMinutes);
    console.log(" Total Minutes:", TotalMinutes);

    return TotalMinutes;
  }
  const timedata = calculateMinutesBetweenTimes(startTime, endTime);
  return (
    <React.Fragment>
      <Content>
        <BlockHead size="lg" className="mb-4">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Payment Details</BlockTitle>
              <BlockDes>
                <p className="fs-6">
                  "All the details related to the cost of the {client?.[activeVariable]?.sectin1?.map((item) => item?.title4) }  and
                  payment".
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none"></BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {!eventFinancials ? (
          <>
            <ShimmerThumbnail height={250} width={1100} rounded />
            <ShimmerThumbnail height={250} width={1100} rounded />
          </>
        ) : retainerPrice || scheduledPayment2 || scheduledPayment3 ? (
          <div>
            <Block>
              {paymentDetailsLoading ? (
                <ShimmerThumbnail height={250} width={500} rounded />
              ) : (
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title"> Payment History </h6>
                  </div>
                  <div className="table-responsive my-3">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">
                            Payment Type
                          </th>
                          <th className="text-center" scope="col">
                            Date
                          </th>
                          <th className="text-center" scope="col">
                            Payment Mode
                          </th>
                          <th className="text-center" scope="col">
                            Payment Status
                          </th>

                          <th className="text-center" scope="col">
                            Amount
                          </th>
                          <th className="text-center" scope="col">
                            Make payment
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {retainerPrice && (
                          <tr className="fw-normal align-baseline">
                            <td className="text-center">Retainer</td>
                            <td className="text-center">-------</td>
                            <td className="text-center">
                              {retainer_mode == "1"
                                ? "Stripe"
                                : retainer_mode == "3"
                                ? " Paypal"
                                : "Pending"}
                            </td>
                            <td
                              className={`text-center ${
                                retainerTxnId ? "text-success" : "text-info"
                              }`}
                            >
                              {retainerTxnId ? "Successful" : "Pending"}
                            </td>
                            <td className="text-center">${retainerPrice}</td>
                            <td className="text-center">
                              <button
                                color="primary"
                                className={`btn btn-lg text-light ${
                                  retainerButtonText === "Paid"
                                    ? "btn-primary"
                                    : "btn-info"
                                }`}
                                onClick={() =>
                                  OpenMakePayment(
                                    1,
                                    retainerPrice,
                                    retainerButtonText
                                  )
                                }
                              >
                                {retainerButtonText}
                              </button>
                            </td>
                          </tr>
                        )}

                        {scheduledPayment2 && (
                          <tr className="fw-normal align-baseline">
                            <td className="text-center">Scheduled Payment 2</td>
                            <td className="text-center">
                              {scheduledPayment2Date}
                            </td>
                            <td className="text-center">
                              {scheduled_payment_2_mode == "1"
                                ? "Stripe"
                                : scheduled_payment_2_mode == "3"
                                ? " Paypal"
                                : "Pending"}
                            </td>
                            <td
                              className={`text-center ${
                                schedulePay2TxnId ? "text-success" : "text-info"
                              }`}
                            >
                              {schedulePay2TxnId ? "Successful" : "Pending"}
                            </td>
                            <td className="text-center">
                              ${scheduledPayment2}
                            </td>
                            <td className="text-center">
                              <button
                                disabled={retainerTxnId == ""}
                                color="primary"
                                className={`btn btn-lg text-light ${
                                  scheduled2ButtonText === "Paid"
                                    ? "btn-primary"
                                    : "btn-info"
                                }`}
                                onClick={() =>
                                  OpenMakePayment(
                                    2,
                                    scheduledPayment2,
                                    scheduled2ButtonText
                                  )
                                }
                              >
                                {scheduled2ButtonText}
                              </button>
                            </td>
                          </tr>
                        )}

                        {scheduledPayment3 && (
                          <tr className="fw-normal align-baseline">
                            <td className="text-center">Scheduled Payment 3</td>
                            <td className="text-center">
                              {scheduledPayment3Date}
                            </td>
                            <td className="text-center">
                              {scheduled_payment_3_mode === "1"
                                ? "Stripe"
                                : scheduled_payment_3_mode === "3"
                                ? "Paypal"
                                : "Pending"}
                            </td>
                            <td
                              className={`text-center ${
                                schedulePay3TxnId ? "text-success" : "text-info"
                              }`}
                            >
                              {schedulePay3TxnId ? "Successful" : "Pending"}
                            </td>
                            <td className="text-center">
                              ${scheduledPayment3}
                            </td>
                            <td className="text-center">
                              <button
                                disabled={
                                  retainerTxnId == "" || schedulePay2TxnId == ""
                                }
                                className={`btn btn-lg text-light ${
                                  scheduled3ButtonText === "Paid"
                                    ? "btn-primary"
                                    : "btn-info"
                                }`}
                                onClick={() =>
                                  OpenMakePayment(
                                    3,
                                    scheduledPayment3,
                                    scheduled3ButtonText
                                  )
                                }
                              >
                                {scheduled3ButtonText}
                              </button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* <div className="text-end py-3">
                      Remaining Payment: ${total_remaining_payment}
                    </div> */}
                  </div>
                </div>
              )}

              <div className="nk-data data-list">
                <div className="data-head">
                  <h6 className="overline-title">Invoice</h6>
                </div>
                <div className="my-3">
                  <table className="table table-bordered ">
                    <thead>
                      <tr>
                        <th scope="col">Item</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {packagePrice && (
                        <tr className="fw-light">
                          <td>Demo Package</td>
                          <td>
                            {packageDuration === "h"
                              ? `${packagePrice} X ${timedata} = ${
                                  packagePrice * timedata
                                }`
                              : `$${packagePrice}`}
                          </td>
                        </tr>
                      )}
                      {addOnTotal && (
                        <tr className="fw-light">
                          <td>Addon Total</td>
                          <td>${addOnTotal}</td>
                        </tr>
                      )}
                      {overtimeRateHourly !== 0 && packageDuration === "d" && (
                        <tr>
                          <td> Overtime Total</td>
                          <td>${overtimeRateHourly}</td>
                        </tr>
                      )}
                      {InvoiceTravelFee !== 0 && packageDuration === "d" && (
                        <tr>
                          <td>Travel Fee </td>
                          <td>${InvoiceTravelFee}</td>
                        </tr>
                      )}
                      {discount1 !== 0 && (
                        <tr>
                          <td> Discount 1</td>
                          <td>${discount1}</td>
                        </tr>
                      )}
                      {discount2 !== 0 && (
                        <tr>
                          <td>Discount 2</td>
                          <td>${discount2}</td>
                        </tr>
                      )}

                      {totalFee && (
                        <tr>
                          <td>Total</td>
                          <td>${totalFee}</td>
                        </tr>
                      )}
                     {/* {retainerPrice && (
                        <tr className="fw-light">
                          <td>Retainer</td>
                          <td>${retainerPrice}</td>
                        </tr>
                      )}*/}
                      {/* {balanceDueAfterRetainer && (
                        <tr className="fw-light">
                          <td>Balance due After Deposit</td>
                          <td>${balanceDueAfterRetainer}</td>
                        </tr>
                      )} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </Block>
          </div>
        ) : (
          <div className="alert alert-warning" role="alert">
            <p className="mb-0">
              <strong>Hey {clientName},</strong> our administrative team is
              currently gathering the necessary details for your upcoming {client?.[activeVariable]?.sectin1?.map((item) => item?.title4) } .
              To ensure a seamless process, please provide your preferred
              payment method to our team by {eventdate}. Thank you for your
              cooperation.
            </p>
          </div>
        )}

        <Button
          className="px-3 my-2 btn-primary"
          color="primary"
          onClick={() => navigate("/my-events")}
        >
          <Icon className="ni-arrow-long-left"></Icon>
          <span> Back</span>
        </Button>
      </Content>
    </React.Fragment>
  );
};

export default PaymentDetails;
